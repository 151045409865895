import { useEffect } from 'react';

const useHandleClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (onClickOutside) {
          onClickOutside();
        }
      }
    };

    global.addEventListener('click', handleClickOutside, true);
    global.addEventListener('touchstart', handleClickOutside, true);
    return () => {
      global.removeEventListener('click', handleClickOutside, true);
      global.removeEventListener('touchstart', handleClickOutside, true);
    };
  }, [onClickOutside, ref]);
};

export default useHandleClickOutside;
