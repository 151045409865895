/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  OptionalSiteLinkWrapper,
  SiteLink,
  Swiper,
  Image as SiteImage,
  VideoPlayer,
} from 'components/shared';
import { getLinkHref } from 'lib/utils/helpers';
import classNames from 'classnames';
import { useIsDesktop } from 'hooks';
import { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import useHandleClickOutside from 'hooks/useHandleClickOutside';
import validateRequired from './validate-required';
import withPropsValidation from './with-props-validation.tsx';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const AdaptiveSwiper = ({ children }) => (
  <Swiper
    className="[&_.center-slides]:!justify-center"
    slidesPerView={1.2}
    spaceBetween={8}
    slidesOffsetBefore={15}
    slidesOffsetAfter={15}
    hideButtons
    threshold={8}
    breakpoints={{
      768: {
        slidesPerView: 'auto',
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        threshold: 5,
      },
    }}
    scrollbar={{
      el: '.swiper-scrollbar-fit-guide',
      draggable: true,
      hide: false,
    }}
    slideToClickedSlide={false}
  >
    {children}
  </Swiper>
);

const NoSwiper = ({ children }) => (
  <div
    style={{
      'grid-template-columns': `repeat(${children.length}, max-content)`,
    }}
    className="w-100% grid place-content-center gap-x-2 [&>div]:w-[26.25rem]"
  >
    {children}
  </div>
);

const TagPoint = ({
  positionX,
  positionY,
  product,
  index,
  activeIndex,
  setActiveIndex,
  parentRef,
}) => {
  const { productTitle, slug } = product;

  if (parentRef.current === null) return null;

  const tooltipContent = (
    <SiteLink
      className="flex items-center !gap-2"
      href={getLinkHref({ __typename: 'PageProduct', slug })}
    >
      <p className="pointer-events-none line-clamp-2 text-xs font-bold leading-[0.825rem] text-blue ">
        {productTitle}
      </p>
      <svg
        className="aspect-1 w-3.5 shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 15"
      >
        <g clipPath="url(#arrow)">
          <path
            stroke="#041C2C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m5.3 4 3.5 3.5L5.2 11"
            opacity=".3"
          />
        </g>
        <defs>
          <clipPath id="arrow">
            <path fill="#fff" d="M0 .5h14v14H0z" />
          </clipPath>
        </defs>
      </svg>
    </SiteLink>
  );

  return (
    <div
      style={{
        '--top': `${Math.min(positionY, 97)}%`,
        '--left': `${Math.min(positionX, 96)}%`,
      }}
    >
      <Tippy
        className="z-50 w-max !bg-white !bg-opacity-40 !p-3 !backdrop-blur-sm [&_.tippy-arrow]:!top-[-1px] [&_.tippy-arrow]:!bg-transparent [&_.tippy-arrow]:!bg-opacity-40 [&_.tippy-arrow]:!text-white  [&_.tippy-arrow]:!opacity-40 [&_.tippy-content]:!p-0"
        interactive
        arrow
        trigger="click"
        visible={activeIndex === index}
        maxWidth="12.5rem"
        animation="scale"
        placement="bottom"
        content={tooltipContent}
        zIndex={50}
        offset={[-8, -8]}
        popperOptions={{
          strategy: 'absolute',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: parentRef.current,
              },
            },
          ],
        }}
      >
        <button
          type="button"
          onClick={e => {
            e.stopPropagation();
            setActiveIndex(index);
          }}
          className="group/point absolute inset-0 left-[var(--left)] top-[var(--top)] h-max w-max p-5"
        >
          <div
            className={classNames(
              'flex h-3.5 w-3.5 items-center justify-center rounded-full border-white bg-blue shadow-[0_0_0_0.5px_#ffffff] transition-all duration-200 ease-in-out md:group-hover/point:shadow-[0_0_0_1.5px_#ffffff]',
              activeIndex === index
                ? 'shadow-[0_0_0_1.5px_#ffffff]'
                : 'shadow-[0_0_0_0.5px_#ffffff]'
            )}
            role="button"
            tabIndex={0}
          >
            <div className="h-1.5 w-1.5 rounded-full bg-white" />
          </div>
        </button>
      </Tippy>
    </div>
  );
};

const ShopTheLookThumbnail = ({
  thumbnailLink,
  thumbnailBackground: { vimeoUrl, image: background },
  tagPointsCollection,
  key,
}) => {
  const [activeIndex, setActiveIndex] = useState();
  const thumbnailRef = useRef(null);
  useHandleClickOutside(thumbnailRef, () => setActiveIndex(null));

  return (
    <div
      className="swiper-slide relative isolate block aspect-[0.75] w-full max-w-[26.25rem]"
      link={getLinkHref(thumbnailLink?.link)}
      key={key}
      ref={thumbnailRef}
    >
      <OptionalSiteLinkWrapper link={thumbnailLink}>
        {vimeoUrl ? (
          <VideoPlayer
            className="absolute inset-0 h-full w-full"
            videoClassName="absolute inset-0 w-full h-full"
            videoURL={vimeoUrl}
          />
        ) : (
          <button
            type="button"
            onTouchStart={() => setActiveIndex(null)}
            onClick={() => setActiveIndex(null)}
          >
            <SiteImage
              src={background}
              sizes="(min-width: 1024px) 40vw, 100vw"
              className="absolute inset-0 h-full w-full"
            />
          </button>
        )}
        {!!tagPointsCollection?.items?.length &&
          tagPointsCollection?.items
            ?.filter(Boolean)
            ?.map(({ positionX, positionY, product }, index) => (
              <TagPoint
                key={product?.sys?.id}
                positionX={positionX}
                positionY={positionY}
                product={product}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                parentRef={thumbnailRef}
                index={index}
              />
            ))}
      </OptionalSiteLinkWrapper>
    </div>
  );
};

const Section = ({ data: { thumbnailsCollection } }) => {
  const isDesktop = useIsDesktop();
  const Container =
    thumbnailsCollection?.items?.length > 4 || !isDesktop ? AdaptiveSwiper : NoSwiper;

  return (
    <div className={classNames('relative py-10 text-blue antialiased md:py-12')}>
      <Container col={thumbnailsCollection?.items?.length || 0}>
        {thumbnailsCollection?.items
          ?.filter(Boolean)
          ?.map(({ sys: { id }, thumbnailLink, thumbnailBackground, tagPointsCollection }) => (
            <ShopTheLookThumbnail
              key={id}
              thumbnailLink={thumbnailLink}
              thumbnailBackground={thumbnailBackground}
              tagPointsCollection={tagPointsCollection}
            />
          ))}
      </Container>
    </div>
  );
};

export default withPropsValidation(Section, ({ data: { thumbnailsCollection } }) =>
  validateRequired({ thumbnails: thumbnailsCollection.items })
);
